<template>
	<div>
		<!-- Blocking Modal -->
		<b-modal v-if="user" ref="blocking-modal" hide-header hide-footer modal-class="modal-primary" centered>
			<div class="p-1">
				<div>
					<h4 class="line-height-condensed">Are you sure you want to block the user <span
							class="text-primary">{{ user.emailAddress }}</span></h4>
				</div>
				<validation-observer ref="blockingValidationObserver">
					<b-form-group class="mt-2" v-slot="{ ariaDescribedby }">
						<strong class="font-medium-2">Select the reason</strong>
						<validation-provider #default="{ errors }" rules="required">
							<span class="text-danger d-block" v-if="errors[0]">Selecting a reason is required</span>
							<b-form-radio-group id="radio-options" class="mt-1" v-model="selectedReason"
								:state="errors.length > 0 ? false : null" :aria-describedby="ariaDescribedby"
								name="blocking-options" stacked>
								<b-form-radio value="violation of policies">
									<strong>Violation of Policies</strong>
									<p>The designer is not adhering to the platform's terms of service or community
										guidelines</p>
								</b-form-radio>
								<b-form-radio value="quality issues">
									<strong>Quality Issues</strong>
									<p>The designer consistently delivers work that doesn't meet the platform's quality
										standards</p>
								</b-form-radio>
								<b-form-radio value="unethical conduct">
									<strong>Unethical Conduct</strong>
									<p>The designer exhibits unprofessional behavior, including harassment,
										discrimination, or dishonesty</p>
								</b-form-radio>
								<b-form-radio value="non-compliance">
									<strong>Non-Compliance</strong>
									<p>The designer fails to comply with platform policies, payment processes, or any
										other essential aspects of using the platform</p>
								</b-form-radio>
								<b-form-radio value="other" class="other-option">
									<strong>Other</strong>
									<b-form-textarea :disabled="selectedReason !== 'other'" class="mt-1"
										v-model="otherReason"
										:state="errors.length > 0 ? false : null"></b-form-textarea>
								</b-form-radio>
							</b-form-radio-group>
						</validation-provider>
					</b-form-group>
				</validation-observer>
				<div class="d-flex justify-content-end mt-3">
					<b-button variant="outline-primary" @click="hideBlockingModal">
						Cancel
					</b-button>
					<b-button variant="primary" @click="deactivateUser" class="ml-1">
						block
					</b-button>
				</div>
			</div>
		</b-modal>
		<!-- /Blocking Modal -->

		<!--	unblocking Modal	-->
		<b-modal v-if="user" ref="unblocking-modal" hide-header hide-footer modal-class="modal-primary" centered>
			<div class="p-1">
				<div>
					<h4 class="line-height-condensed">Are you sure you want to unblock the user <span
							class="text-primary">{{ user.emailAddress }}</span></h4>
				</div>
				<div class="d-flex justify-content-end mt-3">
					<b-button variant="outline-primary" @click="$refs['unblocking-modal'].hide()">
						Cancel
					</b-button>
					<b-button variant="primary" @click="activateUser" class="ml-1">
						unblock
					</b-button>
				</div>
			</div>
		</b-modal>
		<!--	/unblocking Modal	-->

		<!-- Deleting Modal -->
		<b-modal v-if="user" ref="deleting-modal" hide-header hide-footer modal-class="modal-primary" centered>
			<div class="p-1">
				<div>
					<h4 class="line-height-condensed">Are you sure you want to Delete the user <span
							class="text-primary">{{ user.emailAddress }}</span></h4>
				</div>
				<validation-observer ref="deletingValidationObserver">
					<b-form-group class="mt-2" v-slot="{ ariaDescribedby }">
						<strong class="font-medium-2">Select the reason</strong>
						<validation-provider #default="{ errors }" rules="required">
							<span class="text-danger d-block" v-if="errors[0]">Selecting a reason is required</span>
							<b-form-radio-group id="radio-options" class="mt-1" v-model="selectedReason"
								:state="errors.length > 0 ? false : null" :aria-describedby="ariaDescribedby"
								name="deleting-options" stacked>
								<b-form-radio value="user request" class="mb-1">
									<strong>User Request</strong>
								</b-form-radio>
								<b-form-radio value="inactivity" class="mb-1">
									<strong>Inactivity</strong>
								</b-form-radio>
								<b-form-radio value="incorrect email addresses" class="mb-1">
									<strong>Incorrect Email Addresses</strong>
								</b-form-radio>
								<b-form-radio value="other" class="other-option">
									<strong>Other</strong>
									<b-form-textarea :disabled="selectedReason !== 'other'" class="mt-1"
										v-model="otherReason"
										:state="errors.length > 0 ? false : null"></b-form-textarea>
								</b-form-radio>
							</b-form-radio-group>
						</validation-provider>
					</b-form-group>
				</validation-observer>
				<div class="d-flex justify-content-end mt-3">
					<b-button variant="outline-primary" @click="hideDeletingModal">
						Cancel
					</b-button>
					<b-button variant="danger" @click="deleteUser" class="ml-1">
						Delete
					</b-button>
				</div>
			</div>
		</b-modal>
		<!-- /Deleting Modal -->

		<!-- Table Container Card -->
		<b-card no-body class="mb-0">
			<div class="m-2">
				<!-- Table Top -->
				<b-row>
					<!-- Per Page -->
					<b-col cols="12" md="6" class="d-flex align-items-center justify-content-start mb-1 mb-md-0">
						<label>Show</label>
						<v-select v-model="perPage" :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
							:options="perPageOptions" :clearable="false"
							class="per-page-selector d-inline-block mx-50" />
						<label>entries</label>
					</b-col>
					<!-- Search -->
					<b-col cols="12" md="6">
						<div class="d-flex align-items-center justify-content-end">
							<!-- <b-form-select v-model="typeQuery" :options="accountTypes" class="d-inline-block mr-1" /> -->

							<v-select v-model="typeQuery" :options="accountTypes" :clearable="true"
								class="mr-1 w-100" />

							<b-form-input v-model="nameQuery" class="d-inline-block mr-1" placeholder="Name..."
								@keyup.e.enter="fetchUsers" />

							<b-form-input v-model="emailQuery" class="d-inline-block mr-1" placeholder="Email..."
								@keyup.e.enter="fetchUsers" />
							<b-button variant="secondary" @click="fetchUsers" class="mr-2">
								<span class="text-nowrap">Filter</span>
							</b-button>
							<b-button variant="primary" :to="{ name: 'UsersCreate' }">
								<span class="text-nowrap">Add User</span>
							</b-button>
						</div>
					</b-col>
				</b-row>
			</div>

			<b-table ref="usersTable" class="position-relative text-nowrap" :items="users" responsive
				:fields="tableColumns" show-empty empty-text="No matching records found" :sort-by.sync="sortBy"
				:sort-desc.sync="sortDesc">

				<!-- Column: Name -->
				<template #cell(fullName)="data">
					<b-link class="font-weight-bold d-block text-nowrap"
						:to="{ name: 'UsersEdit', params: { userId: data.item.id } }">
						{{ data.item.fullName }}
					</b-link>
				</template>

				<!-- Column: Phone -->
				<template #cell(cellPhone)="data">
					<div class="text-nowrap">
						<span class="align-text-top">{{ data.item.cellPhone }}</span>
					</div>
				</template>

				<!-- Column: Role -->
				<template #cell(role)="data">
					<div class="text-nowrap">
						<feather-icon :icon="getRoleIcon(data.item.role)" size="18" class="mr-50"
							:class="`text-${getRoleVariant(data.item.role)}`" />
						<span class="align-text-top text-capitalize">{{ data.item.role.text }}</span>
					</div>
				</template>

				<!-- Column: Locked Out -->
				<template #cell(isLockedOut)="data">
					<b-badge pill :variant="getLockedOutVariant(data.item.isLockedOut)" class="text-capitalize">
						{{ getLockedOutText(data.item.isLockedOut) }}
					</b-badge>
				</template>

				<!-- Column: User Status -->
				<template #cell(isActive)="data">
					<b-badge pill :variant="getUserStatusVariant(data.item.isActive)" class="text-capitalize">
						<feather-icon :icon="getUserStatusIcon(data.item.isActive)" />
						{{ getUserStatusText(data.item.isActive) }}
					</b-badge>
				</template>

        <!-- Column: Verified -->
        <template #cell(isVerified)="data">
          <b-avatar size="32" :variant="getVerifiedVariant(data.item.isVerified)">
            <feather-icon :icon="getVerifiedIcon(data.item.isVerified)" />
          </b-avatar>
        </template>

				<!-- Column: Actions -->
				<template #cell(actions)="data">
					<b-dropdown variant="link" no-caret :right="$store.state.appConfig.isRTL">
						<template #button-content>
							<feather-icon icon="MoreVerticalIcon" size="16" class="align-middle text-body" />
						</template>

						<b-dropdown-item @click="showBlockingModal(data.item)">
							<feather-icon :icon="data.item.isActive ? 'MinusCircleIcon' : 'CheckCircleIcon'" />
							<span class="align-middle ml-50">{{ data.item.isActive ? 'Block' : 'Unblock' }}</span>
						</b-dropdown-item>

						<b-dropdown-item @click="showDeletingModal(data.item)">
							<feather-icon icon="XCircleIcon" />
							<span class="align-middle ml-50">Delete</span>
						</b-dropdown-item>

						<b-dropdown-item v-if="data.item.isLockedOut" @click="unlock(data.item)">
							<feather-icon icon="UnlockIcon" />
							<span class="align-middle ml-50">Unlock</span>
						</b-dropdown-item>

						<b-dropdown-item :to="{ name: 'UsersEdit', params: { userId: data.item.id } }">
							<feather-icon icon="EditIcon" />
							<span class="align-middle ml-50">Edit</span>
						</b-dropdown-item>
					</b-dropdown>
				</template>
			</b-table>

			<div class="mx-2 mb-2">
				<b-row>
					<b-col cols="12" sm="6"
						class="d-flex align-items-center justify-content-center justify-content-sm-start">
						<span class="text-muted">
							Showing {{ dataMeta.from }} to {{ dataMeta.to }} of {{ dataMeta.of }} entries
						</span>
					</b-col>
					<!-- Pagination -->
					<b-col cols="12" sm="6"
						class="d-flex align-items-center justify-content-center justify-content-sm-end">
						<b-pagination v-model="currentPage" :total-rows="totalUsers" :per-page="perPage" first-number
							last-number class="mb-0 mt-1 mt-sm-0" prev-class="prev-item" next-class="next-item">
							<template #prev-text>
								<feather-icon icon="ChevronLeftIcon" size="18" />
							</template>

							<template #next-text>
								<feather-icon icon="ChevronRightIcon" size="18" />
							</template>
						</b-pagination>
					</b-col>
				</b-row>
			</div>
		</b-card>
	</div>
</template>

<script>
import {
	BCard,
	BRow,
	BCol,
	BFormGroup,
	BFormRadioGroup,
	BFormRadio,
	BFormInput,
	BFormTextarea,
	BButton,
	BTable,
	BPagination,
	BLink,
	BAvatar,
	BBadge,
	BDropdown,
	BDropdownItem,
	BFormSelect
} from 'bootstrap-vue';
import vSelect from 'vue-select';
import ToastificationContent from '@core/components/toastification/ToastificationContent';
import Button from "@/views/components/button/Button.vue";
import Ripple from 'vue-ripple-directive'
import { ValidationObserver, ValidationProvider } from 'vee-validate'
import { required, } from '@validations'

// const AccountType = {
// 	Individual: 'Individual',
// 	Office: 'Office'
// }

export default {
	components: {
		ValidationProvider,
		ValidationObserver,
		Button,
		BCard,
		BRow,
		BCol,
		BLink,
		BAvatar,
		BBadge,
		BFormGroup,
		BFormRadioGroup,
		BFormRadio,
		BFormTextarea,
		BFormInput,
		BButton,
		BTable,
		BDropdown,
		BDropdownItem,
		BPagination,
		vSelect,
		BFormSelect
	},

	data() {
		return {
			currentPage: 1,
			perPage: 100,
			perPageOptions: [10, 25, 50, 100],
			nameQuery: '',
			emailQuery: '',
			typeQuery: '',
			tableColumns: [
				{ key: 'fullName', label: 'Name', sortable: true },
				{ key: 'emailAddress', label: 'Email', sortable: true },
				{ key: 'cellPhone', label: 'Phone' },
				{ key: 'role', sortable: true },
				{ key: 'isLockedOut', sortable: true },
				{ key: 'isActive', label: 'User Status', sortable: true },
        { key: 'isVerified', sortable: true },
				{ key: 'actions' },
			],
			sortBy: 'firstName',
			sortDesc: false,
			users: [],
			totalUsers: 0,
			user: {},
			selectedReason: "",
			otherReason: null,
			required,
			accountTypes: ['Individual', 'Office']
		};
	},

	directives: {
		Ripple,
	},

	computed: {
		dataMeta() {
			return {
				from: this.perPage * (this.currentPage - 1) + (this.users.length ? 1 : 0),
				to: this.perPage * (this.currentPage - 1) + this.users.length,
				of: this.totalUsers,
			}
		},

		queryParams() {
			return {
				sortBy: this.sortBy,
				sortOrder: this.sortDesc ? 1 : 0,
				skip: (this.currentPage - 1) * this.perPage,
				take: this.perPage,
			}
		},
	},

	watch: {
		queryParams: {
			handler() {
				this.fetchUsers();
			},
			immediate: true
		}
	},

	methods: {
		async fetchUsers() {
			const params = this.queryParams;
			params.name = this.nameQuery;
			params.email = this.emailQuery;
			if (this.typeQuery) {
				params.accountType = this.typeQuery;
			}

			const response = await this.$http.get('users', {
				params
			});
			this.users = response.data.results;
			this.totalUsers = response.data.count;
		},

		getRoleIcon(role) {
			const icons = {
				'Operations': 'SettingsIcon',
				'Professionals': 'UserIcon',
				'Administrators': 'ServerIcon',
				'Accounting': 'DollarSignIcon',
				'CMS': 'Edit2Icon',
				'default': 'UserIcon'
			};

			return icons[role.text] || icons.default;
		},

		getRoleVariant(role) {
			const variants = {
				'Operations': 'success',
				'Professionals': 'primary',
				'Administrators': 'danger',
				'Accounting': 'warning',
				'CMS': 'info',
				'default': 'default'
			};

			return variants[role.text] || variants.default;
		},

		getLockedOutVariant(lockedOut) {
			return lockedOut ? 'light-danger' : 'light-success';
		},

		getLockedOutText(lockedOut) {
			return lockedOut ? 'Locked' : 'Unlocked';
		},

		getUserStatusVariant(isActive) {
			return isActive ? 'light-success' : 'light-secondary';
		},

		getUserStatusText(isActive) {
			return isActive ? 'Active' : 'Blocked';
		},

		getUserStatusIcon(isActive) {
			return isActive ? 'CheckCircleIcon' : 'MinusCircleIcon';
		},

		showBlockingModal(user) {
			this.user = user;
			if (user.isActive) {
				this.$refs["blocking-modal"].show();
			} else {
				this.$refs["unblocking-modal"].show();
			}
		},

		hideBlockingModal() {
			this.$refs['blocking-modal'].hide();
			this.selectedReason = '';
			this.otherReason = null;
		},

		showDeletingModal(user) {
			this.user = user;
			this.$refs["deleting-modal"].show();
		},

		hideDeletingModal() {
			this.$refs['deleting-modal'].hide();
			this.selectedReason = '';
			this.otherReason = null;
		},

		async activateUser() {
			this.$refs["unblocking-modal"].hide();
			const response = await this.$http.post(`users/${this.user.id}/activate`);

			const successMessage = `${this.user.fullName} has been unblocked`;

			this.$toast({
				component: ToastificationContent,
				props: {
					title: successMessage,
					icon: 'CheckCircleIcon',
					variant: 'success',
				},
			});

			this.fetchUsers();
		},

		async deactivateUser() {
			const reason = this.selectedReason === 'other' ? this.otherReason : this.selectedReason;

			const valid = this.$refs.blockingValidationObserver.validate().then(async success => {
				if (success) {
					this.selectedReason = '';
					this.otherReason = null;
					this.$refs["blocking-modal"].hide();

					const response = await this.$http.post("users/deactivate", {
						id: this.user.id,
						reason: reason
					});

					const successMessage = `${this.user.fullName} has been blocked`;

					this.$toast({
						component: ToastificationContent,
						props: {
							title: successMessage,
							icon: 'CheckCircleIcon',
							variant: 'success',
						},
					});

					this.fetchUsers();

				} else {
					this.$toast({
						component: ToastificationContent,
						props: {
							title: 'Please select a reason for blocking this user!',
							icon: 'BellIcon',
							variant: 'danger',
						},
					})
				}
				return success
			})
			return valid
		},

		async unlock(user) {
			await this.$http.post(`users/${user.id}/unlock`);

			this.fetchUsers();
		},

		async deleteUser() {
			const reason = this.selectedReason === 'other' ? this.otherReason : this.selectedReason;

			const valid = this.$refs.deletingValidationObserver.validate().then(async success => {
				if (success) {
					this.selectedReason = '';
					this.otherReason = null;
					this.$refs["deleting-modal"].hide();

					const response = await this.$http.post("users/delete", {
						id: this.user.id,
						reason: reason
					});

					const successMessage = `${this.user.fullName} has been deleted`;

					this.$toast({
						component: ToastificationContent,
						props: {
							title: successMessage,
							icon: 'CheckCircleIcon',
							variant: 'success',
						},
					});

					this.fetchUsers();

				} else {
					this.$toast({
						component: ToastificationContent,
						props: {
							title: 'Please select a reason for deleting this user!',
							icon: 'BellIcon',
							variant: 'danger',
						},
					})
				}
				return success
			})
			return valid
		},

    getVerifiedVariant(verified) {
      return verified ? 'light-success' : 'light-danger';
    },

    getVerifiedIcon(verified) {
      return verified ? 'CheckCircleIcon' : 'XIcon';
    },
	}
};
</script>

<style lang="scss" scoped>
.per-page-selector {
	width: 90px;
}
</style>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';

.other-option label {
	width: 100%;
}
</style>
