var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('validation-observer',{ref:"validationObserver"},[_c('image-crop',{ref:"cropper",attrs:{"image":_vm.imageToCrop,"aspect-ratio":1920 / 900},on:{"cropped":_vm.upload,"hidden":_vm.clearImageToCrop}}),_c('b-card-form',[_c('div',[_c('div',[_c('b-form',{ref:"form",staticClass:"repeater-form",style:({ height: _vm.trHeight }),on:{"submit":function($event){$event.preventDefault();return _vm.repeatAgain.apply(null, arguments)}}},[_c('b-row',{ref:"row"},[_c('b-col',{attrs:{"cols":"12"}},[_c('b-row',[_c('b-col',{attrs:{"md":"4","xl":"5"}},[_c('b-form-group',{attrs:{"label":"English Question","label-for":"english-question"}},[_c('validation-provider',{attrs:{"vid":'english-question' + _vm.overlaySection.id,"rules":"required","name":"English Question"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"english-question","type":"text","state":errors.length > 0 ? false : null,"placeholder":"English Question"},model:{value:(_vm.overlaySection.questionEn),callback:function ($$v) {_vm.$set(_vm.overlaySection, "questionEn", $$v)},expression:"overlaySection.questionEn"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"md":"4","xl":"5","dir":"rtl"}},[_c('b-form-group',{attrs:{"label":"Arabic Question","label-for":"arabic-question"}},[_c('validation-provider',{attrs:{"vid":'arabic-question' + _vm.overlaySection.id,"rules":"required","name":"Arabic Question"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"arabic-question","type":"text","state":errors.length > 0 ? false : null,"placeholder":"Arabic Question"},model:{value:(_vm.overlaySection.questionAr),callback:function ($$v) {_vm.$set(_vm.overlaySection, "questionAr", $$v)},expression:"overlaySection.questionAr"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1)],1),_c('hr',{staticClass:"mb-2"}),_vm._l((_vm.overlaySection.answers),function(item,index){return _c('div',{key:item.id,ref:"answerRow",refInFor:true,attrs:{"id":item.id}},[_c('b-row',[_c('b-col',{attrs:{"md":"4","xl":"5"}},[_c('b-form-group',{attrs:{"label":"English Answer","label-for":"english-answer"}},[_c('validation-provider',{attrs:{"vid":'english-answer' + item.id,"rules":"required","name":"English Answer"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"english-answer","type":"text","state":errors.length > 0 ? false : null,"placeholder":"English Answer"},model:{value:(item.titleEn),callback:function ($$v) {_vm.$set(item, "titleEn", $$v)},expression:"item.titleEn"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1)],1),_c('b-col',{attrs:{"md":"4","xl":"5","dir":"rtl"}},[_c('b-form-group',{attrs:{"label":"Arabic Answer","label-for":"arabic-answer"}},[_c('validation-provider',{attrs:{"vid":'arabic-answer' + item.id,"rules":"required","name":"Arabic Answer"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"arabic-answer","type":"text","state":errors.length > 0 ? false : null,"placeholder":"Arabic Answer"},model:{value:(item.titleAr),callback:function ($$v) {_vm.$set(item, "titleAr", $$v)},expression:"item.titleAr"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1)],1)],1),_c('b-row',[_c('b-col',{attrs:{"md":"4","xl":"5"}},[_c('b-form-group',{attrs:{"label":"English Url","label-for":"english-url"}},[_c('validation-provider',{attrs:{"vid":'english-url' + item.id,"rules":"required","name":"English Url"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"english-url","type":"text","state":errors.length > 0 ? false : null,"placeholder":"English Url"},model:{value:(item.urlEn),callback:function ($$v) {_vm.$set(item, "urlEn", $$v)},expression:"item.urlEn"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1)],1),_c('b-col',{attrs:{"md":"4","xl":"5","dir":"rtl"}},[_c('b-form-group',{attrs:{"label":"Arabic Url","label-for":"arabic-url"}},[_c('validation-provider',{attrs:{"vid":'arabic-url' + item.id,"rules":"required","name":"Arabic Url"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"arabic-url","type":"text","state":errors.length > 0 ? false : null,"placeholder":"Arabic Url"},model:{value:(item.urlAr),callback:function ($$v) {_vm.$set(item, "urlAr", $$v)},expression:"item.urlAr"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1)],1),_c('b-col',{staticClass:"mb-50",attrs:{"lg":"2","md":"3"}},[_c('b-button',{directives:[{name:"ripple",rawName:"v-ripple.400",value:('rgba(234, 84, 85, 0.15)'),expression:"'rgba(234, 84, 85, 0.15)'",modifiers:{"400":true}}],staticClass:"mt-0 mt-md-2",attrs:{"variant":"outline-danger"},on:{"click":function($event){return _vm.removeAnswer(index)}}},[_c('feather-icon',{staticClass:"mr-25",attrs:{"icon":"XIcon"}}),_c('span',[_vm._v("Delete")])],1)],1)],1)],1)}),_c('b-row',[_c('b-col',[_c('b-button',{directives:[{name:"ripple",rawName:"v-ripple.400",value:('rgba(255, 255, 255, 0.15)'),expression:"'rgba(255, 255, 255, 0.15)'",modifiers:{"400":true}}],attrs:{"variant":"primary"},on:{"click":_vm.repeatAgain}},[_c('feather-icon',{staticClass:"mr-25",attrs:{"icon":"PlusIcon"}}),_c('span',[_vm._v("Add New")])],1)],1)],1),_c('hr',{staticClass:"mb-2"}),_c('b-row',[_c('b-col',{attrs:{"md":"4","xl":"5"}},[_c('b-form-group',{attrs:{"label":"English Section Title","label-for":"english-section-title"}},[_c('validation-provider',{attrs:{"vid":'english-section-title',"name":"English Section Title"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"english-section-title","type":"text","state":errors.length > 0 ? false : null,"placeholder":"English Section Title"},model:{value:(_vm.overlaySection.extraSectionTitleEn),callback:function ($$v) {_vm.$set(_vm.overlaySection, "extraSectionTitleEn", $$v)},expression:"overlaySection.extraSectionTitleEn"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"md":"4","xl":"5","dir":"rtl"}},[_c('b-form-group',{attrs:{"label":"Arabic Section Title","label-for":"arabic-section-title"}},[_c('validation-provider',{attrs:{"vid":'arabic-section-title',"name":"Arabic Section Title"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"arabic-section-title","type":"text","state":errors.length > 0 ? false : null,"placeholder":"Arabic Section Title"},model:{value:(_vm.overlaySection.extraSectionTitleAr),callback:function ($$v) {_vm.$set(_vm.overlaySection, "extraSectionTitleAr", $$v)},expression:"overlaySection.extraSectionTitleAr"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1)],1),_c('b-row',[_c('b-col',{attrs:{"md":"4","xl":"5"}},[_c('b-form-group',{attrs:{"label":"English Section URL","label-for":"english-section-url"}},[_c('validation-provider',{attrs:{"vid":'english-section-url',"name":"English Section URL"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"english-section-url","type":"text","state":errors.length > 0 ? false : null,"placeholder":"English Section URL"},model:{value:(_vm.overlaySection.extraSectionUrlEn),callback:function ($$v) {_vm.$set(_vm.overlaySection, "extraSectionUrlEn", $$v)},expression:"overlaySection.extraSectionUrlEn"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"md":"4","xl":"5","dir":"rtl"}},[_c('b-form-group',{attrs:{"label":"Arabic Section URL","label-for":"arabic-section-url"}},[_c('validation-provider',{attrs:{"vid":'arabic-section-url',"name":"Arabic Section URL"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"arabic-section-url","type":"text","state":errors.length > 0 ? false : null,"placeholder":"Arabic Section URL"},model:{value:(_vm.overlaySection.extraSectionUrlAr),callback:function ($$v) {_vm.$set(_vm.overlaySection, "extraSectionUrlAr", $$v)},expression:"overlaySection.extraSectionUrlAr"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1)],1),_c('b-row',[_c('b-col',{staticClass:"mb-1",attrs:{"md":"5"}},[_c('label',[_vm._v("Image")]),_c('b-form-file',{attrs:{"type":"file","accept":"image/*"},on:{"change":function($event){return _vm.loadImage($event)}}})],1)],1),(_vm.overlaySection.images.filter(function (x) { return x.url; }).length)?_c('b-row',_vm._l((_vm.overlaySection.images),function(image,index){return _c('b-row',{key:image.id,attrs:{"id":image.id}},[_c('div',{staticClass:"image-container mt-3 mr-5"},[(image.url)?_c('b-img',{attrs:{"fluid":"","src":image.url,"alt":"Image"}}):_vm._e(),_c('feather-icon',{staticClass:"remove-image-icon",attrs:{"icon":"XIcon","size":"20"},on:{"click":function($event){return _vm.removeImage(index)}}})],1)])}),1):_vm._e()],2),_c('b-col',{attrs:{"cols":"12"}},[_c('hr')])],1)],1)],1),_c('b-row',[_c('b-col',{staticClass:"text-right"},[_c('b-button',{directives:[{name:"ripple",rawName:"v-ripple.400",value:('rgba(255, 255, 255, 0.15)'),expression:"'rgba(255, 255, 255, 0.15)'",modifiers:{"400":true}}],attrs:{"variant":"primary"},on:{"click":function($event){return _vm.save(false)}}},[_c('span',[_vm._v("Save")])])],1)],1)],1)])],1)}
var staticRenderFns = []

export { render, staticRenderFns }