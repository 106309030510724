var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('validation-observer',{ref:"validationObserver"},[_c('image-crop',{ref:"cropper",attrs:{"image":_vm.imageToCrop,"aspect-ratio":1920 / 900},on:{"cropped":_vm.upload,"hidden":_vm.clearImageToCrop}}),_c('b-card-form',[_c('div',[_c('div',[_c('b-form',{ref:"form",staticClass:"repeater-form",style:({ height: _vm.trHeight }),on:{"submit":function($event){$event.preventDefault();return _vm.repeatAgain.apply(null, arguments)}}},_vm._l((_vm.items),function(item,index){return _c('b-row',{key:item.id,ref:"row",refInFor:true,attrs:{"id":item.id}},[_c('b-col',{attrs:{"cols":"12"}},[_c('b-row',[_c('b-col',{staticClass:"mb-1",attrs:{"md":"5"}},[(item.isImageLinkedToQuiz)?_c('div',{staticClass:"mb-2"},[_c('span',{staticClass:"bullet bullet-warning svg-font-small-3 mr-50 cursor-pointer"}),_c('span',{staticClass:"font-weight-bolder"},[_vm._v("Warning: This image is related to a quiz")])]):_vm._e(),_c('label',[_vm._v("Image")]),_c('b-form-file',{attrs:{"type":"file","accept":"image/*"},on:{"change":function($event){return _vm.loadImage($event, item, 'mainPhotoUrl')}}}),(item.mainPhotoUrl)?_c('div',{staticClass:"mt-3"},[_c('b-img',{attrs:{"fluid":"","src":item.mainPhotoUrl,"alt":"Image"}})],1):_vm._e()],1)],1),_c('b-row',{ref:("title-" + (item.id)),refInFor:true,attrs:{"id":("title-" + (item.id))}},[_c('b-col',{attrs:{"md":"4","xl":"5"}},[_c('b-form-group',{attrs:{"label":"Title Arabic","label-for":"titleAr"}},[_c('validation-provider',{attrs:{"vid":'titleAr' + item.id,"name":"titleAr"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"titleAr","state":errors.length > 0 ? false : null,"placeholder":"Title Arabic"},on:{"change":function($event){return _vm.updateItem(index)}},model:{value:(item.titleAr),callback:function ($$v) {_vm.$set(item, "titleAr", $$v)},expression:"item.titleAr"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1)],1),_c('b-col',{attrs:{"md":"4","xl":"5"}},[_c('b-form-group',{attrs:{"label":"Title English","label-for":"titleEn"}},[_c('validation-provider',{attrs:{"vid":'titleEn' + item.id,"name":"titleEn"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"titleEn","state":errors.length > 0 ? false : null,"placeholder":"Title English"},on:{"change":function($event){return _vm.updateItem(index)}},model:{value:(item.titleEn),callback:function ($$v) {_vm.$set(item, "titleEn", $$v)},expression:"item.titleEn"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1)],1)],1),_vm._l((item.styles),function(styleItem,styleItemIndex){return _c('b-row',{key:styleItem.id,ref:"styleRow",refInFor:true,attrs:{"id":styleItem.id}},[_c('b-col',{attrs:{"md":"4","xl":"5"}},[_c('b-form-group',{attrs:{"label":"Style","label-for":"style"}},[_c('validation-provider',{attrs:{"vid":'style' + styleItem.id,"rules":"required","name":"StyleId"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-select',{attrs:{"id":"styleId","options":_vm.styles,"state":errors.length > 0 ? false : null},on:{"change":function($event){return _vm.updateItem(index)}},model:{value:(styleItem.styleId),callback:function ($$v) {_vm.$set(styleItem, "styleId", $$v)},expression:"styleItem.styleId"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1)],1),_c('b-col',{attrs:{"md":"4","xl":"5"}},[_c('b-form-group',{attrs:{"label":"Percentage","label-for":"percentage"}},[_c('validation-provider',{attrs:{"vid":'percentage' + styleItem.id,"rules":"required|integer","name":"Percentage"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"percentage","type":"number","number":"","state":errors.length > 0 ? false : null,"placeholder":"Percentage"},on:{"change":function($event){[_vm.validatePercentages(item), _vm.updateItem(index)]}},model:{value:(styleItem.percentage),callback:function ($$v) {_vm.$set(styleItem, "percentage", $$v)},expression:"styleItem.percentage"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1)],1),_c('b-col',{staticClass:"mb-50"},[(styleItemIndex === item.styles.length - 1)?_c('b-button',{directives:[{name:"ripple",rawName:"v-ripple.400",value:('rgba(234, 84, 85, 0.15)'),expression:"'rgba(234, 84, 85, 0.15)'",modifiers:{"400":true}}],staticClass:"mt-0 mt-md-2 mx-1",attrs:{"variant":"outline-primary"},on:{"click":function($event){return _vm.RepeatAgainRow(index)}}},[_c('feather-icon',{attrs:{"icon":"PlusIcon"}})],1):_vm._e(),(
                      item.styles.length > 1 &&
                      styleItemIndex === item.styles.length - 1
                    )?_c('b-button',{directives:[{name:"ripple",rawName:"v-ripple.400",value:('rgba(234, 84, 85, 0.15)'),expression:"'rgba(234, 84, 85, 0.15)'",modifiers:{"400":true}}],staticClass:"mt-0 mt-md-2",attrs:{"variant":"outline-danger"},on:{"click":function($event){return _vm.removeItemRow(index, styleItemIndex)}}},[_c('feather-icon',{attrs:{"icon":"XIcon"}})],1):_vm._e()],1)],1)}),_c('validation-provider',{attrs:{"name":"StyleRow"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                    var errors = ref.errors;
return [_c('small',{ref:("percentage-error-" + (item.id)),refInFor:true,staticClass:"text-danger",attrs:{"id":("percentage-error-" + (item.id))}})]}}],null,true)}),_c('b-row',[(!item.isImageLinkedToQuiz)?_c('b-col',{staticClass:"mb-50 text-right"},[_c('b-button',{directives:[{name:"ripple",rawName:"v-ripple.400",value:('rgba(234, 84, 85, 0.15)'),expression:"'rgba(234, 84, 85, 0.15)'",modifiers:{"400":true}}],staticClass:"mt-0 mt-md-2",attrs:{"variant":"outline-danger"},on:{"click":function($event){return _vm.removeItem(index)}}},[_c('feather-icon',{staticClass:"mr-25",attrs:{"icon":"XIcon"}}),_c('span',[_vm._v("Delete")])],1)],1):_vm._e()],1)],2),_c('b-col',{attrs:{"cols":"12"}},[_c('hr')])],1)}),1)],1),_c('b-row',[_c('b-col',[_c('b-button',{directives:[{name:"ripple",rawName:"v-ripple.400",value:('rgba(255, 255, 255, 0.15)'),expression:"'rgba(255, 255, 255, 0.15)'",modifiers:{"400":true}}],attrs:{"variant":"primary"},on:{"click":_vm.repeatAgain}},[_c('feather-icon',{staticClass:"mr-25",attrs:{"icon":"PlusIcon"}}),_c('span',[_vm._v("Add Test")])],1)],1),_c('b-col',{staticClass:"text-right"},[_c('b-button',{directives:[{name:"ripple",rawName:"v-ripple.400",value:('rgba(255, 255, 255, 0.15)'),expression:"'rgba(255, 255, 255, 0.15)'",modifiers:{"400":true}}],attrs:{"variant":"primary"},on:{"click":function($event){return _vm.save(false)}}},[_c('span',[_vm._v("Save")])])],1)],1)],1)])],1)}
var staticRenderFns = []

export { render, staticRenderFns }