var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('validation-observer',{ref:"validationObserver"},[_c('b-card-form',{attrs:{"title":"Testimonials"}},[_c('div',[_c('div',[_c('b-form',{ref:"form",staticClass:"repeater-form",style:({ height: _vm.trHeight }),on:{"submit":function($event){$event.preventDefault();return _vm.repeatAgain.apply(null, arguments)}}},_vm._l((_vm.testimonials),function(testimonial,index){return _c('b-row',{key:testimonial.id,ref:"row",refInFor:true,attrs:{"id":testimonial.id}},[_c('b-col',{attrs:{"cols":"12"}},[_c('b-row',[_c('b-col',{attrs:{"md":"6","xl":"10"}},[_c('b-form-group',{attrs:{"label":"Professional","label-for":"professional"}},[_c('validation-provider',{attrs:{"vid":'professional' + testimonial.id,"rules":"required","name":"Professional"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-select',{attrs:{"options":testimonial.options && testimonial.options.length ? testimonial.options : _vm.options,"reduce":function (option) { return option.value; },"label":"text","filterable":false,"loading":testimonial.isLoading,"placeholder":"Search for an option"},on:{"search":function($event){return _vm.searchOptions($event, index)}},model:{value:(testimonial.professionalId),callback:function ($$v) {_vm.$set(testimonial, "professionalId", $$v)},expression:"testimonial.professionalId"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1)],1),_c('b-col',{staticClass:"mb-50",attrs:{"lg":"2","md":"3"}},[_c('b-button',{directives:[{name:"ripple",rawName:"v-ripple.400",value:('rgba(234, 84, 85, 0.15)'),expression:"'rgba(234, 84, 85, 0.15)'",modifiers:{"400":true}}],staticClass:"mt-0 mt-md-2",attrs:{"variant":"outline-danger"},on:{"click":function($event){return _vm.removeItem(index)}}},[_c('feather-icon',{staticClass:"mr-25",attrs:{"icon":"XIcon"}}),_c('span',[_vm._v("Delete")])],1)],1)],1),_c('b-row',[_c('b-col',{attrs:{"md":"4","xl":"5"}},[_c('label',{attrs:{"for":"textarea-default"}},[_vm._v("Content English")]),_c('validation-provider',{attrs:{"vid":'contentEn' + testimonial.id,"rules":"required","name":"Content English"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-textarea',{attrs:{"id":"textarea-default","state":errors.length > 0 ? false:null,"placeholder":"Content English","rows":"3"},model:{value:(testimonial.contentEn),callback:function ($$v) {_vm.$set(testimonial, "contentEn", $$v)},expression:"testimonial.contentEn"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1),_c('b-col',{attrs:{"md":"4","xl":"5","dir":"rtl"}},[_c('label',{attrs:{"for":"textarea-default"}},[_vm._v("Content Arabic")]),_c('validation-provider',{attrs:{"vid":'contentAr' + testimonial.id,"rules":"required","name":"Content Arabic"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-textarea',{attrs:{"id":"textarea-default","state":errors.length > 0 ? false:null,"placeholder":"Content Arabic","rows":"3"},model:{value:(testimonial.contentAr),callback:function ($$v) {_vm.$set(testimonial, "contentAr", $$v)},expression:"testimonial.contentAr"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1)],1),_c('b-row',[_c('b-col',{staticClass:"mt-2 mb-1",attrs:{"md":"4","xl":"5"}},[_c('validation-provider',{attrs:{"vid":'isVisible' + testimonial.id,"rules":"required","name":"Is Visible"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-checkbox',{model:{value:(testimonial.isVisible),callback:function ($$v) {_vm.$set(testimonial, "isVisible", $$v)},expression:"testimonial.isVisible"}},[_vm._v(" Is Visible ")]),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1)],1)],1),_c('b-col',{attrs:{"cols":"12"}},[_c('hr')])],1)}),1)],1),_c('b-row',[_c('b-col',[_c('b-button',{directives:[{name:"ripple",rawName:"v-ripple.400",value:('rgba(255, 255, 255, 0.15)'),expression:"'rgba(255, 255, 255, 0.15)'",modifiers:{"400":true}}],attrs:{"variant":"primary"},on:{"click":_vm.repeatAgain}},[_c('feather-icon',{staticClass:"mr-25",attrs:{"icon":"PlusIcon"}}),_c('span',[_vm._v("Add New")])],1)],1),_c('b-col',{staticClass:"text-right"},[_c('b-button',{directives:[{name:"ripple",rawName:"v-ripple.400",value:('rgba(255, 255, 255, 0.15)'),expression:"'rgba(255, 255, 255, 0.15)'",modifiers:{"400":true}}],attrs:{"variant":"primary"},on:{"click":function($event){return _vm.save(false)}}},[_c('span',[_vm._v("Save")])])],1)],1)],1)])],1)}
var staticRenderFns = []

export { render, staticRenderFns }