var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('b-tabs',[_c('b-tab',{attrs:{"active":""},scopedSlots:_vm._u([{key:"title",fn:function(){return [_c('feather-icon',{attrs:{"icon":"HomeIcon"}}),_c('span',[_vm._v("Milestone Templates")])]},proxy:true}])},[_c('validation-observer',{ref:"validationObserver"},[_c('b-card-form',_vm._l((_vm.milestones),function(milestone,milestoneIndex){return _c('div',{key:milestoneIndex,staticClass:"mb-3"},[_c('b-row',{ref:"row",refInFor:true,staticClass:"w-100 ml-0 mb-3 flex-column flex-lg-row",attrs:{"id":milestone.id}},[_c('b-col',[_c('b-form',{ref:("form-" + milestoneIndex),refInFor:true,staticClass:"repeater-form px-1 pt-1 pb-2 pl-lg-2 pr-lg-4 pt-lg-3 ",style:({ height: _vm.trHeight, 'border': 'solid 1px #D8D6DD' }),on:{"submit":function($event){$event.preventDefault();}}},[_c('div',{staticClass:"d-flex flex-wrap flex-lg-nowrap"},[_c('b-col',{staticClass:"mr-lg-3 mt-0 mb-1 mb-lg-0 p-0 text-lg-right font-weight-bold text-dark",staticStyle:{"margin-top":"7px"},attrs:{"sm":"8","lg":"2"}},[_vm._v("Template Name:")]),_c('b-form-group',{staticClass:"title w-100"},[_c('validation-provider',{attrs:{"vid":'templateName' + milestone.id,"name":"Template Name","rules":"required|alphaNumSpaces"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"templateName","type":"text","state":errors.length > 0 ? false : null,"placeholder":"Template Name"},model:{value:(milestone.title),callback:function ($$v) {_vm.$set(milestone, "title", $$v)},expression:"milestone.title"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1)],1),_c('div',{staticClass:"d-flex flex-wrap flex-lg-nowrap mt-1"},[_c('b-col',{staticClass:"mr-lg-3 mt-0 mb-1 mb-lg-0 p-0 text-lg-right font-weight-bold text-dark",staticStyle:{"margin-top":"7px"},attrs:{"sm":"8","lg":"2"}},[_vm._v("Min Budget threshold($):")]),_c('b-form-group',{staticClass:"w-100"},[_c('validation-provider',{attrs:{"vid":'minBudget' + milestone.id,"name":"Min Budget","rules":"required|numeric|minValue:70"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"minBudget","type":"text","state":errors.length > 0 ? false : null,"placeholder":"Min Budget threshold"},model:{value:(milestone.priceFrom),callback:function ($$v) {_vm.$set(milestone, "priceFrom", $$v)},expression:"milestone.priceFrom"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1)],1),_vm._l((milestone.stages),function(stage,stageIndex){return _c('div',{key:stageIndex,staticClass:"mt-1"},[_c('div',{staticClass:"d-flex flex-wrap flex-lg-nowrap stage",on:{"mouseover":function($event){return _vm.handleMouseOver(milestoneIndex, stage.id)},"mouseleave":_vm.handleMouseOut}},[_c('b-col',{staticClass:"mr-lg-3 mb-1 mb-lg-0 p-0 text-lg-right font-weight-bold text-dark d-flex d-lg-block justify-content-between",attrs:{"sm":"12","lg":"2"}},[_c('span',[_vm._v(_vm._s(("Milestone " + (stageIndex + 1) + ":")))]),(_vm.hoveredStage === stage)?_c('feather-icon',{staticClass:"text-danger d-lg-none",staticStyle:{"margin":"3px 7px 0 0"},attrs:{"icon":"XIcon","size":"20"},on:{"click":function($event){return _vm.removeStage(milestoneIndex, stageIndex)}}}):_vm._e()],1),_c('b-form-group',{staticClass:"w-100 mr-0 mr-lg-2",attrs:{"label":"Max Number of revisions","label-for":"numOfRevisions"}},[_c('validation-provider',{attrs:{"vid":'numberOfRevisions' + stage.id,"name":"Number Of Revisions","rules":"numeric|minValue:0|maxValue:10"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"numOfRevisions","type":"text","disabled":"","state":errors.length > 0 ? false : null,"placeholder":"Number Of Revisions"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1),_c('b-form-group',{staticClass:"w-100",attrs:{"label":"Amount(%)","label-for":"amount"}},[_c('validation-provider',{attrs:{"vid":'amount' + stage.id,"name":"Amount","rules":"required|numeric|minValue:0|maxValue:100"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"amount","type":"text","state":errors.length > 0 ? false : null,"placeholder":"Amount"},model:{value:(stage.percentage),callback:function ($$v) {_vm.$set(stage, "percentage", $$v)},expression:"stage.percentage"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1),(_vm.hoveredStage === stage)?_c('feather-icon',{staticClass:"text-danger ml-1 d-none d-lg-block",staticStyle:{"margin-top":"30px"},attrs:{"icon":"XIcon","size":"20"},on:{"click":function($event){return _vm.removeStage(milestoneIndex, stageIndex)}}}):_vm._e()],1)])}),_c('b-row',{staticClass:"px-1 mt-1"},[_c('b-button',{staticClass:"text-nowrap px-1 px-md-2 w-100",attrs:{"variant":"outline-primary"},on:{"click":function($event){return _vm.addStage(milestoneIndex, milestone.stages.length)}}},[_c('feather-icon',{attrs:{"icon":"PlusIcon"}}),_c('span',[_vm._v(" Add New Milestone ")])],1)],1),(_vm.isMilestoneEdited(milestoneIndex))?_c('b-row',{staticClass:"px-1 mt-1 justify-content-md-end"},[_c('b-button',{staticClass:"w-100",attrs:{"variant":"primary"},on:{"click":function($event){return _vm.save(milestone)}}},[_vm._v(" Save Changes ")])],1):_vm._e()],2)],1)],1),(milestoneIndex !== _vm.milestones.length - 1)?_c('hr'):_vm._e()],1)}),0)],1)],1),_c('b-tab',{scopedSlots:_vm._u([{key:"title",fn:function(){return [_c('feather-icon',{attrs:{"icon":"ToolIcon"}}),_c('span',[_vm._v("Number of Revisions")])]},proxy:true}])},[_c('NumberOfRevision')],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }