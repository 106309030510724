<template>
    <validation-observer ref="validationObserver">
        <b-card-form>
            <!-- Revisions -->
            <b-row>
                <b-col>
                    <b-form class=" px-1 pt-1 pb-2 pl-lg-2 pr-lg-4 pt-lg-3 "
                            @submit.prevent="">

                        <div class="d-flex flex-wrap flex-lg-nowrap mt-1 mb-2">
                            <b-col sm="8" lg="3" class="mr-lg-3 mt-0 mb-3 mb-lg-0 p-0 text-dark" style="margin-top: 7px;width: 286px; height: 33px; font-size: 20px; ">

                                <p class="font-weight-bold" style="font-size:20px;font-weight: 600!important ;white-space: nowrap">NUMBER OF REVISIONS</p>

                            </b-col>
                        </div>

                        <div class="d-flex flex-wrap flex-lg-nowrap ">

                            <b-col sm="8" lg="1" class="mr-lg-3 mt-0 mb-0 mb-lg-0 p-0  font-weight-bold text-dark" style="margin-top: 7px;font-size:16px;font-weight: 600!important;white-space: nowrap">By Project</b-col>
                            <b-form-group style="width:115px !important;">
                                <validation-provider #default="{ errors }"
                                                     name="By Project"
                                                     rules="required|numeric">
                                    <b-form-input id="byProject"
                                                  v-model="settings.projectMaxRevisionNumber"
                                                  type="text"
                                                  :state="errors.length > 0 ? false : null"
                                                  placeholder="" />
                                    <small class="text-danger">{{ errors[0] }}</small>
                                </validation-provider>
                            </b-form-group>
                        </div>

                        <div class="d-flex flex-wrap flex-lg-nowrap ">
                            <b-col sm="8" lg="1" class="mr-lg-3 mt-0 mb-0 mb-lg-0 p-0  font-weight-bold text-dark" style="margin-top: 7px;font-size:16px;font-weight: 600!important;white-space: nowrap">By Milestone</b-col>
                            <b-form-group style="width:115px !important;">
                                <validation-provider #default="{ errors }"
                                                     name="By Milestone"
                                                     rules="required|numeric">
                                    <b-form-input id="byMilestone"
                                                  v-model="settings.milestoneMaxRevisionNumber"
                                                  type="text"
                                                  :state="errors.length > 0 ? false : null"
                                                  placeholder="" />
                                    <small class="text-danger">{{ errors[0] }}</small>
                                </validation-provider>
                            </b-form-group>
                        </div>

                        <div class="d-flex flex-wrap flex-lg-nowrap mt-1">
                            <b-col sm="8" lg="1" class="mr-lg-3 mt-0 mb-1 mb-lg-0 p-0  " style="margin-top: 7px">
                                <b-button variant="primary" @click="save()"  style=" white-space: nowrap">
                                    Save Changes
                                </b-button>
                            </b-col>
                        </div>
                    </b-form>

                </b-col>
            </b-row>

        </b-card-form>
    </validation-observer>
</template>

<script>
    import BCardForm from '@/components/b-card-form';
    import {
        BTabs,
        BTab,
        BForm,
        BFormGroup,
        BFormInput,
        BFormSelect,
        BRow,
        BCol,
        BButton,
        BFormFile,
        BImg,
    } from 'bootstrap-vue'
    import { heightTransition } from '@core/mixins/ui/transition'
    import Ripple from 'vue-ripple-directive'
    import UUID from '@/libs/uuid'
    import { ValidationProvider, ValidationObserver } from 'vee-validate'
    import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
    import { required, numeric, minValue, maxValue, alphaNumSpaces } from '@validations'


    export default {
        components: {
            ValidationProvider,
            ValidationObserver,
            BForm,
            BRow,
            BCol,
            BButton,
            BFormGroup,
            BFormInput,
            BFormSelect,
            BFormFile,
            BCardForm,
            BImg,
            BTabs,
            BTab
        },
        directives: {
            Ripple,
        },
        mixins: [heightTransition],
        data() {
            return {
                isLoading: false,
                settings: [],
                required,
                numeric,
            }
        },
        methods: {
            fetchSettings() {
                this.$http.get('/Projects/Settings')
                    .then(response => {
                        this.settings = response.data;

                    })
            },

            save() {
                const valid = this.$refs.validationObserver
                    .validate()
                    .then(success => {
                        if (success) {
                            this.$http.post(`/Projects/Settings`, {
                                projectMaxRevisionNumber: this.settings.projectMaxRevisionNumber,
                                milestoneMaxRevisionNumber: this.settings.milestoneMaxRevisionNumber
                            })
                                .then(() => {
                                    this.$toast({
                                        component: ToastificationContent,
                                        props: {
                                            title: 'Save Successfully',
                                            icon: 'CheckCircleIcon',
                                            variant: 'success',
                                        },
                                    });

                                    this.fetchSettings();
                                })
                        } else {
                            this.$toast({
                                component: ToastificationContent,
                                props: {
                                    title: 'Check the fields validations above to be able to save!',
                                    icon: 'BellIcon',
                                    variant: 'danger',
                                },
                            })
                        }
                        return success
                    })
                return valid
            }
        },
        mounted() {
            this.fetchSettings();
        },
    }


</script>
<style>
    @media screen and (min-width: 992px) {
        .form-group:not(.title), button {
            width: fit-content !important;
        }

        [dir=ltr] .mr-lg-3, [dir=ltr] .mx-lg-3 {
            margin-right: 3rem !important;
            margin-left: 90px;
        }
    }
</style>