<template>
  <validation-observer ref="validationObserver">
    <b-card-form title="Testimonials">
      <div>
        <div>
          <b-form
            ref="form"
            :style="{ height: trHeight }"
            class="repeater-form"
            @submit.prevent="repeatAgain"
          >
            <!-- Row Loop -->
            <b-row
              v-for="(testimonial, index) in testimonials"
              :id="testimonial.id"
              :key="testimonial.id"
              ref="row"
            >
              <b-col cols="12">
                <b-row>
                  <!-- Item Name -->
                  <b-col
                    md="6"
                    xl="10"
                  >
                    <b-form-group
                      label="Professional"
                      label-for="professional"
                    >
                      <validation-provider
                        #default="{ errors }"
                        :vid="'professional' + testimonial.id"
                        rules="required"
                        name="Professional"
                      >
                        <v-select
                            v-model="testimonial.professionalId"
                            :options="testimonial.options && testimonial.options.length ? testimonial.options : options"
                            :reduce="option => option.value"
                            label="text"
                            :filterable="false"
                            @search="searchOptions($event, index)"
                            :loading="testimonial.isLoading"
                            placeholder="Search for an option"
                        >
                        </v-select>
                        <small class="text-danger">{{ errors[0] }}</small>
                      </validation-provider>
                    </b-form-group>
                  </b-col>

                  <!-- Remove Button -->
                  <b-col
                    lg="2"
                    md="3"
                    class="mb-50"
                  >
                    <b-button
                      v-ripple.400="'rgba(234, 84, 85, 0.15)'"
                      variant="outline-danger"
                      class="mt-0 mt-md-2"
                      @click="removeItem(index)"
                    >
                      <feather-icon
                        icon="XIcon"
                        class="mr-25"
                      />
                      <span>Delete</span>
                    </b-button>
                  </b-col>
                </b-row>
                <b-row>
                  <b-col
                    md="4"
                    xl="5"
                  >
                    <label for="textarea-default">Content English</label>
                    <validation-provider
                      #default="{ errors }"
                      :vid="'contentEn' + testimonial.id"
                      rules="required"
                      name="Content English"
                    >
                      <b-form-textarea
                        id="textarea-default"
                        v-model="testimonial.contentEn"
                        :state="errors.length > 0 ? false:null"
                        placeholder="Content English"
                        rows="3"
                      />
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-col>

                  <!-- Item Name -->
                  <b-col
                    md="4"
                    xl="5"
                    dir="rtl"
                  >
                    <label for="textarea-default">Content Arabic</label>
                    <validation-provider
                      #default="{ errors }"
                      :vid="'contentAr' + testimonial.id"
                      rules="required"
                      name="Content Arabic"
                    >
                      <b-form-textarea
                        id="textarea-default"
                        v-model="testimonial.contentAr"
                        :state="errors.length > 0 ? false:null"
                        placeholder="Content Arabic"
                        rows="3"
                      />
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-col>
                </b-row>
                <b-row>
                  <b-col
                    md="4"
                    xl="5"
                    class="mt-2 mb-1"
                  >
                    <validation-provider
                        #default="{ errors }"
                        :vid="'isVisible' + testimonial.id"
                        rules="required"
                        name="Is Visible"
                    >
                      <b-form-checkbox
                          v-model="testimonial.isVisible"
                      >
                        Is Visible
                      </b-form-checkbox>
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-col>
                </b-row>
              </b-col>
              <b-col cols="12">
                <hr>
              </b-col>
            </b-row>
          </b-form>
        </div>
        <b-row>
          <b-col>
            <b-button
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              variant="primary"
              @click="repeatAgain"
            >
              <feather-icon
                icon="PlusIcon"
                class="mr-25"
              />
              <span>Add New</span>
            </b-button>
          </b-col>
          <b-col class="text-right">
            <b-button
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              variant="primary"
              @click="save(false)"
            >
              <span>Save</span>
            </b-button>
          </b-col>
        </b-row>
      </div>
    </b-card-form>
  </validation-observer>
</template>

<script>
import BCardForm from '@/components/b-card-form'
import {
  BForm,
  BFormGroup,
  BFormInput,
  BRow,
  BCol,
  BButton,
  BFormFile,
  BImg,
  BFormTextarea, BFormCheckbox,
} from 'bootstrap-vue'
import { heightTransition } from '@core/mixins/ui/transition'
import Ripple from 'vue-ripple-directive'
import UUID from '@/libs/uuid'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import { required } from '@validations'
import vSelect from 'vue-select';
import store from '@/store';

export default {
  components: {
    BFormCheckbox,
    ValidationProvider,
    ValidationObserver,
    BForm,
    BRow,
    BCol,
    BButton,
    BFormGroup,
    BFormInput,
    BFormFile,
    BCardForm,
    BImg,
    BFormTextarea,
    vSelect
  },

  directives: {
    Ripple,
  },

  mixins: [heightTransition],

  data() {
    return {
      testimonials: [],
      options: [],
      required,
      data: {},
      isLoading: false
    }
  },

  methods: {
    async searchOptions(value, index) {
      store.commit('appConfig/TOGGLE_ALLOW_LOADING', false);
      this.testimonials[index].isLoading = true;

      await this.$http.get(`/professionals?Name=${value}&Skip=0&Take=50`)
          .then(response => {
            this.professionals = response.data.results

            const options = response.data.results?.map(prof => {
              return {
                text: prof.fullName,
                value: prof.id
              }
            })

            this.$set(this.testimonials, index, {
              ...this.testimonials[index],
              options,
            });
          })

      this.testimonials[index].isLoading = false;
      store.commit('appConfig/TOGGLE_ALLOW_LOADING', true);
    },

    repeatAgain() {
      this.testimonials.push({
        id: UUID(),
        isVisible: false,
        options: [...this.options],
        isLoading: false
      })
    },
    removeItem(index) {
      this.testimonials.splice(index, 1)
    },
    save(isPublish) {
      const valid = this.$refs.validationObserver
        .validate()
        .then(success =>  {
          if (success) {
            const data = { testimonials: this.testimonials }
            Promise.all([
              this.$http.post('/testimonials', data)]).then(()=>{
                if(!isPublish)
                {
                  this.$toast({
                    component: ToastificationContent,
                    props: {
                      title: "Save Successfully",
                      icon: 'CheckCircleIcon',
                      variant: 'success',
                    },
                  });
                }
              })
          } else {
            this.$toast({
              component: ToastificationContent,
              props: {
                title: 'Check the feilds validations above to be able to save!',
                icon: 'BellIcon',
                variant: 'danger',
              },
            })
          }
          return success
        })
      return valid
    },
  },

  async created() {
    const response = await this.$http.get('/testimonials');

    this.testimonials = response.data;

    await this.$http.get('/professionals?skip=0&take=50')
        .then(response => {
          this.professionals = response.data.results

           this.options = response.data.results.map(prof => {
            return {
              text: prof.fullName,
              value: prof.id
            }
          })
        })
  }
}
</script>

<style lang="scss" scoped>
.repeater-form {
  overflow: hidden;
  transition: 0.35s height;
}
</style>
