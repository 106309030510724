<template>
  <validation-observer ref="validationObserver">
    <image-crop ref="cropper" :image="imageToCrop" @cropped="upload" :aspect-ratio="1920 / 900"
      @hidden="clearImageToCrop" />

    <b-card-form>
      <div>
        <div>
          <b-form ref="form" :style="{ height: trHeight }" class="repeater-form" @submit.prevent="repeatAgain">
            <!-- Row Loop -->
            <b-row v-for="(item, index) in items" :id="item.id" :key="item.id" ref="row">
              <b-col cols="12">

                <!-- Title -->
                <b-row>
                  <b-col md="4" xl="5">
                    <b-form-group label="English Title" label-for="english-title">
                      <validation-provider #default="{ errors }" :vid="'english-title' + item.id" rules="required"
                        name="English Title">
                        <b-form-input id="english-title" v-model="item.titleEn" type="text"
                          :state="errors.length > 0 ? false : null" placeholder="English Title" />
                        <small class="text-danger">{{ errors[0] }}</small>
                      </validation-provider>
                    </b-form-group>
                  </b-col>

                  <b-col md="4" xl="5" dir="rtl">
                    <b-form-group label="Arabic Title" label-for="arabic-title">
                      <validation-provider #default="{ errors }" :vid="'arabic-title' + item.id" rules="required"
                        name="Arabic Title">
                        <b-form-input id="arabic-title" v-model="item.titleAr" type="text"
                          :state="errors.length > 0 ? false : null" placeholder="Arabic Title" />
                        <small class="text-danger">{{ errors[0] }}</small>
                      </validation-provider>
                    </b-form-group>
                  </b-col>

                  <!-- Remove Button -->
                  <b-col lg="2" md="3" class="mb-50">
                    <b-button v-ripple.400="'rgba(234, 84, 85, 0.15)'" variant="outline-danger" class="mt-0 mt-md-2"
                      @click="removeItem(index)">
                      <feather-icon icon="XIcon" class="mr-25" />
                      <span>Delete</span>
                    </b-button>
                  </b-col>

                </b-row>

                <!-- Button title -->
                <b-row>
                  <b-col md="4" xl="5">
                    <b-form-group label="Button English Title" label-for="btn-english-title">
                      <!-- <validation-provider #default="{ errors }" :vid="'btn-english-title' + item.id"
                        name="Button English Title"> -->
                      <b-form-input id="btn-english-title" v-model="item.buttonTitleEn" type="text"
                        placeholder="Button English Title" />
                      <!-- <small class="text-danger">{{ errors[0] }}</small> -->
                      <!-- </validation-provider> -->
                    </b-form-group>
                  </b-col>

                  <b-col md="4" xl="5" dir="rtl">
                    <b-form-group label="Button Arabic Title" label-for="btn-arabic-title">
                      <!-- <validation-provider #default="{ errors }" :vid="'btn-arabic-title' + item.id"
                        name="Button Arabic Title"> -->
                      <b-form-input id="btn-arabic-title" v-model="item.buttonTitleAr" type="text"
                        placeholder="Button Arabic Title" />
                      <!-- <small class="text-danger">{{ errors[0] }}</small> -->
                      <!-- </validation-provider> -->
                    </b-form-group>
                  </b-col>
                </b-row>

                <!-- Button Url -->
                <b-row>
                  <b-col md="4" xl="5">
                    <b-form-group label="Button English Url" label-for="btn-english-url">
                      <!-- <validation-provider #default="{ errors }" :vid="'btn-english-url' + item.id"
                        name="Button English Url"> -->
                      <b-form-input id="btn-english-url" v-model="item.buttonUrlEn" type="text"
                        placeholder="Button English Url" />
                      <!-- <small class="text-danger">{{ errors[0] }}</small> -->
                      <!-- </validation-provider> -->
                    </b-form-group>
                  </b-col>

                  <b-col md="4" xl="5" dir="rtl">
                    <b-form-group label="Button Arabic Url" label-for="btn-arabic-url">
                      <!-- <validation-provider #default="{ errors }" :vid="'btn-arabic-url' + item.id"
                        name="Button Arabic Url"> -->
                      <b-form-input id="btn-arabic-url" v-model="item.buttonUrlAr" type="text"
                        placeholder="Button Arabic Url" />
                      <!-- <small class="text-danger">{{ errors[0] }}</small>
                      </validation-provider> -->
                    </b-form-group>
                  </b-col>
                </b-row>

                <hr class="mb-2">

                <!-- Image Upload -->
                <b-row>
                  <b-col md="5" class="mb-1">
                    <label>English Image</label>
                    <b-form-file type="file" @change="loadImage($event, item, 'mainPhotoUrlEn')" accept="image/*" />

                    <div v-if="item.mainPhotoUrlEn" class="mt-3">
                      <b-img fluid :src="item.mainPhotoUrlEn" alt="English Image" />
                    </div>
                  </b-col>

                  <b-col md="5" class="mb-1">
                    <label>Arabic Image</label>
                    <b-form-file type="file" @change="loadImage($event, item, 'mainPhotoUrlAr')" accept="image/*" />

                    <div v-if="item.mainPhotoUrlAr" class="mt-3">
                      <b-img fluid :src="item.mainPhotoUrlAr" alt="Arabic Image" />
                    </div>
                  </b-col>
                </b-row>

              </b-col>

              <b-col cols="12">
                <hr>
              </b-col>

            </b-row>
          </b-form>
        </div>

        <b-row>
          <b-col>
            <b-button v-ripple.400="'rgba(255, 255, 255, 0.15)'" variant="primary" @click="repeatAgain">
              <feather-icon icon="PlusIcon" class="mr-25" />
              <span>Add New</span>
            </b-button>
          </b-col>
          <b-col class="text-right">
            <b-button v-ripple.400="'rgba(255, 255, 255, 0.15)'" variant="primary" @click="save(false)">
              <span>Save</span>
            </b-button>
          </b-col>
        </b-row>

      </div>
    </b-card-form>
  </validation-observer>
</template>

<script>
import BCardForm from '@/components/b-card-form';
import {
  BForm,
  BFormGroup,
  BFormInput,
  BRow,
  BCol,
  BButton,
  BFormFile,
  BImg,
} from 'bootstrap-vue'
import { heightTransition } from '@core/mixins/ui/transition'
import Ripple from 'vue-ripple-directive'
import base64Encode from '@/libs/UploadFilesService'
import { getMimeType } from '@/libs/UploadFilesService'
import UUID from '@/libs/uuid'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import { required } from '@validations'
import ImageCrop from '@/components/ImageCrop';

export default {
  components: {
    ValidationProvider,
    ValidationObserver,
    BForm,
    BRow,
    BCol,
    BButton,
    BFormGroup,
    BFormInput,
    BFormFile,
    BCardForm,
    BImg,
    ImageCrop
  },
  directives: {
    Ripple,
  },
  mixins: [heightTransition],
  data() {
    return {
      isLoading: false,
      items: [],
      nextTodoId: 1,
      imageToCrop: {
        name: null,
        index: null,
        src: null,
        type: null
      },
      required
    }
  },
  created() {
    this.$http.get('/homepage/firstSections')
      .then(response => {
        this.items = response.data
      })
  },
  methods: {
    repeatAgain() {
      this.items.push({
        id: UUID(),
      })
    },
    removeItem(index) {
      this.items.splice(index, 1)
    },
    save(isPublish) {
      const valid = this.$refs.validationObserver
        .validate()
        .then(success => {
          if (success) {
            const data = { sections: this.items }
            this.$http.post('/homepage/firstSections', data)
              .then(() => {
                if (!isPublish) {
                  this.$toast({
                    component: ToastificationContent,
                    props: {
                      title: 'Save Successfully',
                      icon: 'CheckCircleIcon',
                      variant: 'success',
                    },
                  });
                }
              })
          } else {
            this.$toast({
              component: ToastificationContent,
              props: {
                title: 'Check the fields validations above to be able to save!',
                icon: 'BellIcon',
                variant: 'danger',
              },
            })
          }
          return success
        })
      return valid
    },

    loadImage(event, item, name) {
      const { files } = event.target;

      if (files && files[0]) {
        if (this.imageToCrop.src) {
          URL.revokeObjectURL(this.imageToCrop.src)
        }

        const blob = URL.createObjectURL(files[0]);

        const reader = new FileReader();

        reader.onload = (e) => {
          this.imageToCrop = {
            name: name,
            index: this.items.findIndex(element => element.id === item.id),
            src: blob,
            type: getMimeType(e.target.result, files[0].type)
          };
        };

        reader.readAsArrayBuffer(files[0]);

        this.$refs.cropper.show();
      }
    },

    upload(blob) {
      this.isLoading = true;

      base64Encode(blob)
        .then(value => {
          const config = { headers: { 'Content-Type': 'application/json' } }
          this.$http
            .post('/uploads', JSON.stringify(value), config)
            .then(response => {
              const index = this.imageToCrop.index;
              const name = this.imageToCrop.name;

              this.items[index][name] = response.data;
            })
            .finally(() => {
              this.isLoading = false
            })
        })
    },

    clearImageToCrop(event) {
      this.imageToCrop = {
        name: null,
        index: null,
        src: null,
        type: null
      }
    },
  },
}
</script>

<style lang="scss" scoped>
.repeater-form {
  overflow: hidden;
  transition: 0.35s height;
}
</style>
