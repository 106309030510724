<template>
  <!-- Question EN / AR -->

  <!-- 
    Answers List 
      Answer EN / AR
      URL EN / AR
  -->

  <!-- Extra Title EN / Ar -->
  <!-- Extra URL EN / Ar -->
  <!-- Images -->

  <validation-observer ref="validationObserver">
    <image-crop ref="cropper" :image="imageToCrop" @cropped="upload" :aspect-ratio="1920 / 900"
      @hidden="clearImageToCrop" />

    <b-card-form>
      <div>
        <div>
          <b-form ref="form" :style="{ height: trHeight }" class="repeater-form" @submit.prevent="repeatAgain">
            <!-- Row Loop -->
            <b-row ref="row">
              <b-col cols="12">

                <!-- Title -->
                <b-row>
                  <b-col md="4" xl="5">
                    <b-form-group label="English Question" label-for="english-question">
                      <validation-provider #default="{ errors }" :vid="'english-question' + overlaySection.id"
                        rules="required" name="English Question">
                        <b-form-input id="english-question" v-model="overlaySection.questionEn" type="text"
                          :state="errors.length > 0 ? false : null" placeholder="English Question" />
                        <small class="text-danger">{{ errors[0] }}</small>
                      </validation-provider>
                    </b-form-group>
                  </b-col>
                  <b-col md="4" xl="5" dir="rtl">
                    <b-form-group label="Arabic Question" label-for="arabic-question">
                      <validation-provider #default="{ errors }" :vid="'arabic-question' + overlaySection.id"
                        rules="required" name="Arabic Question">
                        <b-form-input id="arabic-question" v-model="overlaySection.questionAr" type="text"
                          :state="errors.length > 0 ? false : null" placeholder="Arabic Question" />
                        <small class="text-danger">{{ errors[0] }}</small>
                      </validation-provider>
                    </b-form-group>
                  </b-col>
                </b-row>

                <hr class="mb-2">

                <!-- Answers -->
                <div v-for="(item, index) in overlaySection.answers" :id="item.id" :key="item.id" ref="answerRow">
                  <b-row>
                    <b-col md="4" xl="5">
                      <b-form-group label="English Answer" label-for="english-answer">
                        <validation-provider #default="{ errors }" :vid="'english-answer' + item.id" rules="required"
                          name="English Answer">
                          <b-form-input id="english-answer" v-model="item.titleEn" type="text"
                            :state="errors.length > 0 ? false : null" placeholder="English Answer" />
                          <small class="text-danger">{{ errors[0] }}</small>
                        </validation-provider>
                      </b-form-group>
                    </b-col>
                    <b-col md="4" xl="5" dir="rtl">
                      <b-form-group label="Arabic Answer" label-for="arabic-answer">
                        <validation-provider #default="{ errors }" :vid="'arabic-answer' + item.id" rules="required"
                          name="Arabic Answer">
                          <b-form-input id="arabic-answer" v-model="item.titleAr" type="text"
                            :state="errors.length > 0 ? false : null" placeholder="Arabic Answer" />
                          <small class="text-danger">{{ errors[0] }}</small>
                        </validation-provider>
                      </b-form-group>
                    </b-col>
                  </b-row>
                  <b-row>
                    <b-col md="4" xl="5">
                      <b-form-group label="English Url" label-for="english-url">
                        <validation-provider #default="{ errors }" :vid="'english-url' + item.id" rules="required"
                          name="English Url">
                          <b-form-input id="english-url" v-model="item.urlEn" type="text"
                            :state="errors.length > 0 ? false : null" placeholder="English Url" />
                          <small class="text-danger">{{ errors[0] }}</small>
                        </validation-provider>
                      </b-form-group>
                    </b-col>
                    <b-col md="4" xl="5" dir="rtl">
                      <b-form-group label="Arabic Url" label-for="arabic-url">
                        <validation-provider #default="{ errors }" :vid="'arabic-url' + item.id" rules="required"
                          name="Arabic Url">
                          <b-form-input id="arabic-url" v-model="item.urlAr" type="text"
                            :state="errors.length > 0 ? false : null" placeholder="Arabic Url" />
                          <small class="text-danger">{{ errors[0] }}</small>
                        </validation-provider>
                      </b-form-group>
                    </b-col>
                    <!-- Remove Button -->
                    <b-col lg="2" md="3" class="mb-50">
                      <b-button v-ripple.400="'rgba(234, 84, 85, 0.15)'" variant="outline-danger" class="mt-0 mt-md-2"
                        @click="removeAnswer(index)">
                        <feather-icon icon="XIcon" class="mr-25" />
                        <span>Delete</span>
                      </b-button>
                    </b-col>
                  </b-row>
                </div>

                <b-row>
                  <b-col>
                    <b-button v-ripple.400="'rgba(255, 255, 255, 0.15)'" variant="primary" @click="repeatAgain">
                      <feather-icon icon="PlusIcon" class="mr-25" />
                      <span>Add New</span>
                    </b-button>
                  </b-col>
                </b-row>

                <hr class="mb-2">

                <b-row>
                  <b-col md="4" xl="5">
                    <b-form-group label="English Section Title" label-for="english-section-title">
                      <validation-provider #default="{ errors }" :vid="'english-section-title'"
                        name="English Section Title">
                        <b-form-input id="english-section-title" v-model="overlaySection.extraSectionTitleEn"
                          type="text" :state="errors.length > 0 ? false : null" placeholder="English Section Title" />
                        <small class="text-danger">{{ errors[0] }}</small>
                      </validation-provider>
                    </b-form-group>
                  </b-col>
                  <b-col md="4" xl="5" dir="rtl">
                    <b-form-group label="Arabic Section Title" label-for="arabic-section-title">
                      <validation-provider #default="{ errors }" :vid="'arabic-section-title'"
                        name="Arabic Section Title">
                        <b-form-input id="arabic-section-title" v-model="overlaySection.extraSectionTitleAr" type="text"
                          :state="errors.length > 0 ? false : null" placeholder="Arabic Section Title" />
                        <small class="text-danger">{{ errors[0] }}</small>
                      </validation-provider>
                    </b-form-group>
                  </b-col>
                </b-row>

                <b-row>
                  <b-col md="4" xl="5">
                    <b-form-group label="English Section URL" label-for="english-section-url">
                      <validation-provider #default="{ errors }" :vid="'english-section-url'"
                        name="English Section URL">
                        <b-form-input id="english-section-url" v-model="overlaySection.extraSectionUrlEn" type="text"
                          :state="errors.length > 0 ? false : null" placeholder="English Section URL" />
                        <small class="text-danger">{{ errors[0] }}</small>
                      </validation-provider>
                    </b-form-group>
                  </b-col>
                  <b-col md="4" xl="5" dir="rtl">
                    <b-form-group label="Arabic Section URL" label-for="arabic-section-url">
                      <validation-provider #default="{ errors }" :vid="'arabic-section-url'" name="Arabic Section URL">
                        <b-form-input id="arabic-section-url" v-model="overlaySection.extraSectionUrlAr" type="text"
                          :state="errors.length > 0 ? false : null" placeholder="Arabic Section URL" />
                        <small class="text-danger">{{ errors[0] }}</small>
                      </validation-provider>
                    </b-form-group>
                  </b-col>
                </b-row>

                <!-- Image Upload -->
                <b-row>
                  <b-col md="5" class="mb-1">
                    <label>Image</label>
                    <b-form-file type="file" @change="loadImage($event)" accept="image/*" />
                    <!-- <div v-if="item.mainPhotoUrlEn" class="mt-3">
                      <b-img fluid :src="item.mainPhotoUrlEn" alt="English Image" />
                    </div> -->
                  </b-col>
                </b-row>

                <b-row v-if="overlaySection.images.filter(x => x.url).length">
                  <!-- <b-col md="5" class="mb-1" > -->
                  <b-row v-for="(image, index) in overlaySection.images" :id="image.id" :key="image.id">
                    <!-- <b-form-file type="file" @change="loadImage($event, item, 'mainPhotoUrlAr')" accept="image/*" /> -->
                    <div class="image-container mt-3 mr-5">
                      <b-img v-if="image.url" fluid :src="image.url" alt="Image" />
                      <feather-icon icon="XIcon" size="20" class="remove-image-icon" @click="removeImage(index)" />
                    </div>
                  </b-row>
                  <!-- </b-col> -->
                </b-row>

              </b-col>

              <b-col cols="12">
                <hr>
              </b-col>

            </b-row>
          </b-form>
        </div>

        <b-row>
          <b-col class="text-right">
            <b-button v-ripple.400="'rgba(255, 255, 255, 0.15)'" variant="primary" @click="save(false)">
              <span>Save</span>
            </b-button>
          </b-col>
        </b-row>

      </div>
    </b-card-form>
  </validation-observer>
</template>

<script>
import BCardForm from '@/components/b-card-form';
import {
  BForm,
  BFormGroup,
  BFormInput,
  BRow,
  BCol,
  BButton,
  BFormFile,
  BImg,
} from 'bootstrap-vue'
import { heightTransition } from '@core/mixins/ui/transition'
import Ripple from 'vue-ripple-directive'
import base64Encode from '@/libs/UploadFilesService'
import { getMimeType } from '@/libs/UploadFilesService'
import UUID from '@/libs/uuid'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import { required } from '@validations'
import ImageCrop from '@/components/ImageCrop';

export default {
  components: {
    ValidationProvider,
    ValidationObserver,
    BForm,
    BRow,
    BCol,
    BButton,
    BFormGroup,
    BFormInput,
    BFormFile,
    BCardForm,
    BImg,
    ImageCrop
  },
  directives: {
    Ripple,
  },
  mixins: [heightTransition],
  data() {
    return {
      isLoading: false,
      items: [],
      nextTodoId: 1,
      imageToCrop: {
        name: null,
        index: null,
        src: null,
        type: null
      },
      required,
      overlaySection: {
        id: null,
        questionEn: '',
        questionAr: '',
        extraSectionTitleEn: '',
        extraSectionTitleAr: '',
        extraSectionUrlEn: '',
        extraSectionUrlAr: '',
        answers: [
          {
            id: null,
            homePageOverlaySectionId: null,
            titleEn: '',
            titleAr: '',
            urlEn: '',
            urlAr: ''
          }
        ],
        images: [
          {
            id: null,
            homePageOverlaySectionId: null,
            url: '',
          }
        ],
      }
    }
  },
  created() {
    this.$http.get('/homepage/overlaysection')
      .then(response => {
        this.overlaySection = response.data
      })
  },
  methods: {
    repeatAgain() {
      this.overlaySection.answers.push({
        id: null,
        homePageOverlaySectionId: null,
        titleEn: '',
        titleAr: '',
        urlEn: '',
        urlAr: ''
      })
    },

    removeAnswer(index) {
      this.overlaySection.answers.splice(index, 1)
    },

    removeImage(index) {
      this.overlaySection.images.splice(index, 1)
    },

    save(isPublish) {
      const valid = this.$refs.validationObserver
        .validate()
        .then(success => {
          if (success) {

            const data = { ...this.overlaySection }

            if (!data.id) {
              delete data.id
            }

            data.answers.forEach(answer => {
              if (!answer.id) {
                delete answer.id
              }
              if (!answer.homePageOverlaySectionId) {
                delete answer.homePageOverlaySectionId
              }
            });

            data.images.forEach(image => {
              if (!image.id) {
                delete image.id
              }
              if (!image.homePageOverlaySectionId) {
                delete image.homePageOverlaySectionId
              }
            });


            this.$http.post('/homepage/overlaysection', data)
              .then(() => {
                if (!isPublish) {
                  this.$toast({
                    component: ToastificationContent,
                    props: {
                      title: 'Save Successfully',
                      icon: 'CheckCircleIcon',
                      variant: 'success',
                    },
                  });
                }

                this.$http.get('/homepage/overlaysection')
                  .then(response => {
                    this.overlaySection = response.data
                  })
              })
          } else {
            this.$toast({
              component: ToastificationContent,
              props: {
                title: 'Check the fields validations above to be able to save!',
                icon: 'BellIcon',
                variant: 'danger',
              },
            })
          }
          return success
        })
      return valid
    },

    loadImage(event) {
      const { files } = event.target;


      if (files && files[0]) {
        if (this.imageToCrop.src) {
          URL.revokeObjectURL(this.imageToCrop.src)
        }

        const blob = URL.createObjectURL(files[0]);
        // console.log('blob: ', blob);
        // this.upload(blob)
        const reader = new FileReader();

        reader.onload = (e) => {
          this.imageToCrop = {
            src: blob,
            type: getMimeType(e.target.result, files[0].type)
          };
        };

        reader.readAsArrayBuffer(files[0]);

        this.$refs.cropper.show();
      }
    },

    upload(blob) {
      this.isLoading = true;

      console.log(blob);

      base64Encode(blob)
        .then(value => {
          const config = { headers: { 'Content-Type': 'application/json' } }
          this.$http
            .post('/uploads', JSON.stringify(value), config)
            .then(response => {
              this.overlaySection.images.push({
                id: null,
                homePageOverlaySectionId: null,
                url: response.data
              });
            })
            .finally(() => {
              this.isLoading = false
            })
        })
    },

    clearImageToCrop(event) {
      this.imageToCrop = {
        name: null,
        index: null,
        src: null,
        type: null
      }
    },
  },
}
</script>

<style lang="scss" scoped>
.repeater-form {
  overflow: hidden;
  transition: 0.35s height;
}

.image-container {
  position: relative;

  .remove-image-icon {
    position: absolute;
    top: -5px;
    right: -5px;
    color: black;
    background-color: white;
    border-radius: 50%;
    cursor: pointer;
  }
}
</style>
