export default [
  {
    // Homepage
    path: '/cms/homepage',
    name: 'cms-homepage',
    component: () => import('@/views/cms/homepage/Homepage.vue'),
    meta: {
      resource: 'CMS',
      action: 'manage',
      pageTitle: 'Homepage',
      breadcrumb: [
        {
          text: 'CMS',
        },
        {
          text: 'Homepage',
          active: true,
        },
      ],
    },
  },
  // About Us
  {
    path: '/cms/aboutus',
    name: 'cms-aboutus',
    component: () => import('@/views/cms/aboutus/AboutUs.vue'),
    meta: {
      resource: 'CMS',
      action: 'manage',
      pageTitle: 'About Us',
      breadcrumb: [
        {
          text: 'CMS',
        },
        {
          text: 'About Us',
          active: true,
        },
      ],
    },
  },
  // Contact Us
  {
    path: '/cms/contactus',
    name: 'cms-contactus',
    component: () => import('@/views/cms/contactus/ContactUs.vue'),
    meta: {
      resource: 'CMS',
      action: 'manage',
      pageTitle: 'Contact Us',
      breadcrumb: [
        {
          text: 'CMS',
        },
        {
          text: 'Contact Us',
          active: true,
        },
      ],
    },
  },
  // FAQ
  {
    path: '/cms/faq',
    name: 'cms-faq',
    component: () => import('@/views/cms/faq/FAQ.vue'),
    meta: {
      resource: 'CMS',
      action: 'manage',
      pageTitle: 'FAQ',
      breadcrumb: [
        {
          text: 'CMS',
        },
        {
          text: 'FAQ',
          active: true,
        },
      ],
    },
  },
  // Work With Us
  {
    path: '/cms/workwithus',
    name: 'cms-workwithus',
    component: () => import('@/views/cms/workwithus/WorkWithUs.vue'),
    meta: {
      resource: 'CMS',
      action: 'manage',
      pageTitle: 'WorkWithUs',
      breadcrumb: [
        {
          text: 'CMS',
        },
        {
          text: 'WorkWithUs',
          active: true,
        },
      ],
    },
  },

  // Users
  {
    path: '/cms/users',
    name: 'UsersIndex',
    component: () => import('@/views/cms/users/Index.vue'),
    meta: {
      resource: 'CMS',
      action: 'manage',
      pageTitle: 'Users',
      breadcrumb: [
        {
          text: 'CMS',
        },
        {
          text: 'Users',
          active: true,
        },
      ],
    },
  },

  {
    path: '/cms/users/create',
    name: 'UsersCreate',
    component: () => import('@/views/cms/users/Create.vue'),
    meta: {
      resource: 'CMS',
      action: 'manage',
      pageTitle: 'Add User',
      breadcrumb: [
        {
          text: 'CMS',
        },
        {
          text: 'Add User',
          active: true,
        },
      ],
      navActiveLink: 'UsersIndex'
    },
  },

  {
    path: '/cms/users/:userId/edit',
    name: 'UsersEdit',
    component: () => import('@/views/cms/users/Edit.vue'),
    meta: {
      resource: 'CMS',
      action: 'manage',
      pageTitle: 'Edit User',
      breadcrumb: [
        {
          text: 'CMS',
        },
        {
          text: 'Edit User',
          active: true,
        },
      ],
      navActiveLink: 'UsersIndex'
    },
    props: true,
  },

  // Applications
  {
    path: '/cms/applications',
    name: 'ApplicationsIndex',
    component: () => import('@/views/cms/applications/Index.vue'),
    meta: {
      resource: 'CMS',
      action: 'manage',
      pageTitle: 'Applications',
      breadcrumb: [
        {
          text: 'CMS',
        },
        {
          text: 'Applications',
          active: true,
        },
      ],
    },
  },

  {
    path: '/cms/application/:applicationId/show',
    name: 'ApplicationsShow',
    component: () => import('@/views/cms/applications/Show.vue'),
    meta: {
      resource: 'CMS',
      action: 'manage',
      pageTitle: 'Application',
      breadcrumb: [
        {
          text: 'CMS',
        },
        {
          text: 'Application',
          active: true,
        },
      ],
      navActiveLink: 'ApplicationsIndex'
    },
    props: true,
  },

  // Freelancers
  {
    path: '/cms/freelancers',
    name: 'FreelancersIndex',
    component: () => import('@/views/cms/freelancers/Index.vue'),
    meta: {
      resource: 'CMS',
      action: 'manage',
      pageTitle: 'Designers',
      breadcrumb: [
        {
          text: 'CMS',
        },
        {
          text: 'Designers',
          active: true,
        },
      ],
    },
  },

  {
    path: '/cms/freelancers/:professionalId/show',
    name: 'FreelancersShow',
    component: () => import('@/views/cms/freelancers/Show.vue'),
    meta: {
      resource: 'CMS',
      action: 'manage',
      pageTitle: 'Show Designer',
      breadcrumb: [
        {
          text: 'CMS',
        },
        {
          text: 'Show Designer',
          active: true,
        },
      ],
      navActiveLink: 'FreelancersIndex'
    },
    props: true,
  },

  {
    path: '/cms/payments',
    name: 'PaymentsIndex',
    component: () => import('@/views/cms/payments/Index.vue'),
    meta: {
      resource: 'CMS',
      action: 'manage',
      pageTitle: 'Payments',
      breadcrumb: [
        {
          text: 'CMS',
        },
        {
          text: 'Payments',
          active: true,
        },
      ],
    },
  },

  {
    path: '/cms/payments/:professionalId/:monthNumber/:year/pay',
    name: 'PaymentsShow',
    component: () => import('@/views/cms/payments/Pay.vue'),
    meta: {
      resource: 'CMS',
      action: 'manage',
      pageTitle: 'Payments',
      breadcrumb: [
        {
          text: 'CMS',
        },
        {
          text: 'Payments',
          active: true,
        },
      ],
      navActiveLink: 'PaymentsIndex'
    },
    props: true,
  },

  // Quiz
  {
    path: '/cms/quiz',
    name: 'quizIndex',
    component: () => import('@/views/cms/quiz/Index.vue'),
    meta: {
      resource: 'CMS',
      action: 'manage',
      pageTitle: 'Quiz',
      breadcrumb: [
        {
          text: 'CMS'
        },
        {
          text: 'Quiz',
          active: true
        }
      ]
    }
  },

  // Projects
  {
    path: '/cms/projects',
    name: 'projectsIndex',
    component: () => import('@/views/cms/projects/Index.vue'),
    meta: {
      resource: 'CMS',
      action: 'manage',
      pageTitle: 'Projects',
      breadcrumb: [
        {
          text: 'CMS'
        },
        {
          text: 'Projects',
          active: true
        }
      ]
    }
  },

  {
    path: '/cms/projects/:projectId',
    name: 'ProjectDetails',
    component: () => import('@/views/cms/projects/Details.vue'),
    meta: {
      resource: 'CMS',
      action: 'manage',
      pageTitle: 'Project Details',
      breadcrumb: [
        {
          text: 'CMS',
        },
        {
          text: 'Project Details',
          active: true,
        },
      ],
      navActiveLink: 'projectsIndex'
    },
    props: true,
  },

  // Clients
  {
    path: '/cms/clients',
    name: 'ClientsIndex',
    component: () => import('@/views/cms/clients/Index.vue'),
    meta: {
      resource: 'CMS',
      action: 'manage',
      pageTitle: 'Clients',
      breadcrumb: [
        {
          text: 'CMS',
        },
        {
          text: 'Clients',
          active: true,
        },
      ],
    },
  },

  // Milestones
  {
    path: '/cms/milestones',
    name: 'MilestonesIndex',
    component: () => import('@/views/cms/milestones/Index.vue'),
    meta: {
      resource: 'CMS',
      action: 'manage',
      pageTitle: 'Milestones',
      breadcrumb: [
        {
          text: 'CMS',
        },
        {
          text: 'Milestones',
          active: true,
        },
      ],
    },
  },

  // Testimonials
  {
    path: '/cms/testimonials',
    name: 'testimonials',
    component: () => import('@/views/cms/testimonials/Testimonials.vue'),
    meta: {
      resource: 'CMS',
      action: 'manage',
      pageTitle: 'Testimonials',
      breadcrumb: [
        {
          text: 'CMS',
        },
        {
          text: 'Testimonials',
          active: true,
        },
      ],
    },
  },
]
