<template>
  <validation-observer ref="validationObserver">
    <image-crop
      ref="cropper"
      :image="imageToCrop"
      @cropped="upload"
      :aspect-ratio="1920 / 900"
      @hidden="clearImageToCrop"
    />

    <b-card-form>
      <div>
        <div>
          <b-form
            ref="form"
            :style="{ height: trHeight }"
            class="repeater-form"
            @submit.prevent="repeatAgain"
          >
            <!-- Row Loop -->
            <b-row
              v-for="(item, index) in items"
              :id="item.id"
              :key="item.id"
              ref="row"
            >
              <b-col cols="12">
                <b-row>
                  <b-col
                    md="5"
                    class="mb-1"
                  >
                    <div
                      v-if="item.isImageLinkedToQuiz"
                      class="mb-2"
                    >
                      <span
                        class="bullet bullet-warning svg-font-small-3 mr-50 cursor-pointer"
                      />
                      <span class="font-weight-bolder"
                        >Warning: This image is related to a quiz</span
                      >
                    </div>

                    <label>Image</label>
                    <b-form-file
                      type="file"
                      @change="loadImage($event, item, 'mainPhotoUrl')"
                      accept="image/*"
                    />

                    <div
                      v-if="item.mainPhotoUrl"
                      class="mt-3"
                    >
                      <b-img
                        fluid
                        :src="item.mainPhotoUrl"
                        alt="Image"
                      />
                    </div>
                  </b-col>
                </b-row>
                <b-row
                  :id="`title-${item.id}`"
                  :ref="`title-${item.id}`"
                >
                  <b-col
                    md="4"
                    xl="5"
                  >
                    <b-form-group
                      label="Title Arabic"
                      label-for="titleAr"
                    >
                      <validation-provider
                        #default="{ errors }"
                        :vid="'titleAr' + item.id"
                        name="titleAr"
                      >
                        <b-form-input
                          id="titleAr"
                          v-model="item.titleAr"
                          @change="updateItem(index)"
                          :state="errors.length > 0 ? false : null"
                          placeholder="Title Arabic"
                        />
                        <small class="text-danger">{{ errors[0] }}</small>
                      </validation-provider>
                    </b-form-group>
                  </b-col>

                  <b-col
                    md="4"
                    xl="5"
                  >
                    <b-form-group
                      label="Title English"
                      label-for="titleEn"
                    >
                      <validation-provider
                        #default="{ errors }"
                        :vid="'titleEn' + item.id"
                        name="titleEn"
                      >
                        <b-form-input
                          id="titleEn"
                          v-model="item.titleEn"
                          @change="updateItem(index)"
                          :state="errors.length > 0 ? false : null"
                          placeholder="Title English"
                        />
                        <small class="text-danger">{{ errors[0] }}</small>
                      </validation-provider>
                    </b-form-group>
                  </b-col>
                </b-row>

                <b-row
                  v-for="(styleItem, styleItemIndex) in item.styles"
                  :id="styleItem.id"
                  :key="styleItem.id"
                  ref="styleRow"
                >
                  <b-col
                    md="4"
                    xl="5"
                  >
                    <b-form-group
                      label="Style"
                      label-for="style"
                    >
                      <validation-provider
                        #default="{ errors }"
                        :vid="'style' + styleItem.id"
                        rules="required"
                        name="StyleId"
                      >
                        <b-form-select
                          id="styleId"
                          v-model="styleItem.styleId"
                          @change="updateItem(index)"
                          :options="styles"
                          :state="errors.length > 0 ? false : null"
                        />
                        <small class="text-danger">{{ errors[0] }}</small>
                      </validation-provider>
                    </b-form-group>
                  </b-col>

                  <b-col
                    md="4"
                    xl="5"
                  >
                    <b-form-group
                      label="Percentage"
                      label-for="percentage"
                    >
                      <validation-provider
                        #default="{ errors }"
                        :vid="'percentage' + styleItem.id"
                        rules="required|integer"
                        name="Percentage"
                      >
                        <b-form-input
                          id="percentage"
                          v-model="styleItem.percentage"
                          type="number"
                          number
                          :state="errors.length > 0 ? false : null"
                          placeholder="Percentage"
                          @change="
                            [validatePercentages(item), updateItem(index)]
                          "
                        />
                        <small class="text-danger">{{ errors[0] }}</small>
                      </validation-provider>
                    </b-form-group>
                  </b-col>

                  <!-- Remove Button -->
                  <b-col class="mb-50">
                    <b-button
                      v-if="styleItemIndex === item.styles.length - 1"
                      v-ripple.400="'rgba(234, 84, 85, 0.15)'"
                      variant="outline-primary"
                      class="mt-0 mt-md-2 mx-1"
                      @click="RepeatAgainRow(index)"
                    >
                      <feather-icon icon="PlusIcon" />
                    </b-button>
                    <b-button
                      v-if="
                        item.styles.length > 1 &&
                        styleItemIndex === item.styles.length - 1
                      "
                      v-ripple.400="'rgba(234, 84, 85, 0.15)'"
                      variant="outline-danger"
                      class="mt-0 mt-md-2"
                      @click="removeItemRow(index, styleItemIndex)"
                    >
                      <feather-icon icon="XIcon" />
                    </b-button>
                  </b-col>
                </b-row>

                <validation-provider
                  #default="{ errors }"
                  name="StyleRow"
                >
                  <small
                    :id="`percentage-error-${item.id}`"
                    :ref="`percentage-error-${item.id}`"
                    class="text-danger"
                  ></small>
                </validation-provider>
                <!-- Image Upload -->

                <b-row>
                  <!-- Remove Button -->
                  <b-col
                    class="mb-50 text-right"
                    v-if="!item.isImageLinkedToQuiz"
                  >
                    <b-button
                      v-ripple.400="'rgba(234, 84, 85, 0.15)'"
                      variant="outline-danger"
                      class="mt-0 mt-md-2"
                      @click="removeItem(index)"
                    >
                      <feather-icon
                        icon="XIcon"
                        class="mr-25"
                      />
                      <span>Delete</span>
                    </b-button>
                  </b-col>
                </b-row>
              </b-col>

              <b-col cols="12">
                <hr />
              </b-col>
            </b-row>
          </b-form>
        </div>

        <b-row>
          <b-col>
            <b-button
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              variant="primary"
              @click="repeatAgain"
            >
              <feather-icon
                icon="PlusIcon"
                class="mr-25"
              />
              <span>Add Test</span>
            </b-button>
          </b-col>
          <b-col class="text-right">
            <b-button
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              variant="primary"
              @click="save(false)"
            >
              <span>Save</span>
            </b-button>
          </b-col>
        </b-row>
      </div>
    </b-card-form>
  </validation-observer>
</template>

<script>
import BCardForm from "@/components/b-card-form";
import {
  BForm,
  BFormGroup,
  BFormInput,
  BFormSelect,
  BRow,
  BCol,
  BButton,
  BFormFile,
  BImg,
} from "bootstrap-vue";
import { heightTransition } from "@core/mixins/ui/transition";
import Ripple from "vue-ripple-directive";
import base64Encode from "@/libs/UploadFilesService";
import { getMimeType } from "@/libs/UploadFilesService";
import UUID from "@/libs/uuid";
import { ValidationProvider, ValidationObserver } from "vee-validate";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import { required, integer } from "@validations";
import ImageCrop from "@/components/ImageCrop";

export default {
  components: {
    ValidationProvider,
    ValidationObserver,
    BForm,
    BRow,
    BCol,
    BButton,
    BFormGroup,
    BFormInput,
    BFormSelect,
    BFormFile,
    BCardForm,
    BImg,
    ImageCrop,
  },
  directives: {
    Ripple,
  },
  mixins: [heightTransition],
  data() {
    return {
      isLoading: false,
      items: [],
      itemsToDelete: [],
      itemsToAdd: [],
      itemsToUpdate: [],
      styles: [],
      nextTodoId: 1,
      imageToCrop: {
        name: null,
        index: null,
        src: null,
        type: null,
      },
      required,
      integer,
    };
  },
  created() {
    this.$http.get("/quiz/quizimages").then((response) => {
      this.items = response.data;
    });

    this.$http.get("/lookups/styles").then((response) => {
      this.styles = response.data;
    });
  },
  methods: {
    repeatAgain() {
      const item = {
        id: UUID(),
        styles: [
          {
            id: UUID(),
          },
        ],
      };

      this.items.push(item);
      this.itemsToAdd.push(item);
    },
    RepeatAgainRow(index) {
      console.log("RepeatAgainRow items: ", this.items);

      this.items[index].styles.push({
        id: UUID(),
      });

      const itemToAdd = this.itemsToAdd.find(
        (i) => i.id === this.items[index]?.id
      );
      const itemToUpdate = this.itemsToUpdate.find(
        (i) => i.id === this.items[index]?.id
      );

      if (!itemToUpdate && !itemToAdd) {
        this.itemsToUpdate.push(this.items[index]);
      }
    },
    removeItem(index) {
      const addedItem = this.itemsToAdd.find(
        (i) => i.id === this.items[index]?.id
      );
      const addedItemIndex = this.itemsToAdd.findIndex(
        (i) => i.id === this.items[index]?.id
      );
      const updatedItem = this.itemsToUpdate.find(
        (i) => i.id === this.items[index]?.id
      );
      const updatedItemIndex = this.itemsToUpdate.findIndex(
        (i) => i.id === this.items[index]?.id
      );

      if (addedItem) {
        this.itemsToAdd.splice(addedItemIndex, 1);
      } else {
        if (updatedItem) this.itemsToUpdate.splice(updatedItemIndex, 1);

        this.itemsToDelete.push(this.items[index]);
      }
      this.items.splice(index, 1);
    },
    removeItemRow(index, styleItemIndex) {
      console.log("INDEX: ", index);
      console.log("STYLE ITEM INDEX: ", styleItemIndex);
      console.log("STYLE: ", this.items);
      this.items[index].styles.splice(styleItemIndex, 1);
      this.validatePercentages(this.items[index]);
    },
    save(isPublish) {
      const valid = this.$refs.validationObserver.validate().then((success) => {
        if (success) {
          const data = {
            quizImagesToAdd: [],
            quizImagesToUpdate: [],
            quizImagesToDelete: [],
          };

          data.quizImagesToAdd = this.itemsToAdd;
          data.quizImagesToUpdate = this.itemsToUpdate;
          data.quizImagesToDelete = this.itemsToDelete;

          this.$http.post("/quiz/quizimages", data).then(() => {
            if (!isPublish) {
              this.$toast({
                component: ToastificationContent,
                props: {
                  title: "Save Successfully",
                  icon: "CheckCircleIcon",
                  variant: "success",
                },
              });

              this.itemsToAdd = [];
              this.itemsToUpdate = [];
              this.itemsToDelete = [];
            }
          });
        } else {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: "Check the fields validations above to be able to save!",
              icon: "BellIcon",
              variant: "danger",
            },
          });
        }
        return success;
      });
      return valid;
    },

    loadImage(event, item, name) {
      const { files } = event.target;

      if (files && files[0]) {
        if (this.imageToCrop.src) {
          URL.revokeObjectURL(this.imageToCrop.src);
        }

        const blob = URL.createObjectURL(files[0]);

        const reader = new FileReader();

        reader.onload = (e) => {
          this.imageToCrop = {
            name: name,
            index: this.items.findIndex((element) => element.id === item.id),
            src: blob,
            type: getMimeType(e.target.result, files[0].type),
          };

          let img = new Image();
          img.src = window.URL.createObjectURL(files[0]);

          img.onload = () => {
            this.upload(files[0], img.width, img.height);
          };
        };

        reader.readAsArrayBuffer(files[0]);
        // this.$refs.cropper.show();
      }
    },

    upload(blob, width, height) {
      this.isLoading = true;

      base64Encode(blob).then((value) => {
        const config = { headers: { "Content-Type": "application/json" } };
        this.$http
          .post("/uploads", JSON.stringify(value), config)
          .then((response) => {
            const index = this.imageToCrop.index;
            const name = this.imageToCrop.name;

            this.items[index][name] = response.data;
            this.items[index]["width"] = width;
            this.items[index]["height"] = height;

            this.updateItem(index);
            this.$forceUpdate();
          })
          .finally(() => {
            this.isLoading = false;
          });
      });
    },

    clearImageToCrop(event) {
      this.imageToCrop = {
        name: null,
        index: null,
        src: null,
        type: null,
      };
    },

    updateItem(index) {
      const itemToAdd = this.itemsToAdd.find(
        (i) => i.id === this.items[index]?.id
      );
      const itemToUpdate = this.itemsToUpdate.find(
        (i) => i.id === this.items[index]?.id
      );

      if (!itemToUpdate && !itemToAdd) {
        this.itemsToUpdate.push(this.items[index]);
      }
    },

    validatePercentages(item) {
      const percentages = item.styles.map((o) => parseInt(o.percentage));

      let sum = 0;
      percentages.forEach((percentage) => {
        sum += percentage;
      });

      if (sum !== 100) {
        this.$refs[`percentage-error-${item.id}`][0].innerHTML =
          "Percentages summation must be 100%";
        return false;
      } else {
        this.$refs[`percentage-error-${item.id}`][0].innerHTML = "";
        return true;
      }
    },
  },
};
</script>

<style
  lang="scss"
  scoped
>
.repeater-form {
  overflow: hidden;
  transition: 0.35s height;
}
</style>
